import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { Item } from "../pojo/Item";

//author: travis
export class PendingObjectService {
    public static deleteAll(eventId: number, itemTypeId: number) {
        var objType = itemTypeId === 4 ? "sp" : "rs";
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/" + objType + "_pend_rsrvs.json?event_id=" + eventId,
                "PendingObjectService.DeleteAll",
            ),
            null,
        );
    }

    public static delete(eventId: number, itemId: number, itemTypeId: number) {
        var objType = itemTypeId === 4 ? "sp" : "rs";
        var itemTypeName = S25Const.typeId2ItemId[itemTypeId];
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/" + objType + "_pend_rsrvs.json?event_id=" + eventId + "&" + itemTypeName + "=" + itemId,
                "PendingObjectService.Delete",
            ),
            null,
        );
    }

    public static async deleteMulti(
        itemTypeId: Item.Ids.Location | Item.Ids.Resource,
        reservations: { eventId: number; profileId?: number; itemIds?: number[] }[],
    ) {
        const prefix = itemTypeId === Item.Ids.Location ? "sp" : "rs";
        const url = `/${prefix}_pend_rsrvs.json?request_method=delete`;
        const injected = DataAccess.injectCaller(url, "PendingObjectService.DeleteMulti");
        return DataAccess.put(injected, reservations);
    }

    //objOccs: [{startDt, endDt, itemId}]
    public static create(eventId: number, profileId: number, objOccs: any, itemTypeId: number) {
        let itemHash: any = {};
        if (objOccs && objOccs.length) {
            objOccs.sort(S25Util.shallowSortDates("startDt", "endDt"));
            jSith.forEach(objOccs, function (_: any, objOcc: any) {
                let startTime = S25Util.date.toS25ISOTimeStr(objOcc.startDt);
                let endTime = S25Util.date.toS25ISOTimeStr(objOcc.endDt);
                let key =
                    itemTypeId +
                    "&" +
                    objOcc.itemId +
                    "&" +
                    startTime +
                    "&" +
                    endTime +
                    "&" +
                    objOcc.reservation_id +
                    "&" +
                    objOcc.stock;
                if (!itemHash[key]) {
                    itemHash[key] = {
                        event_id: eventId,
                        profile_id: profileId,
                        rsrv_start_dt: S25Util.date.toS25ISODateTimeStr(objOcc.startDt),
                        rsrv_end_dt: S25Util.date.toS25ISODateTimeStr(objOcc.endDt),
                        //removing use of datelist so we can send reservation id
                        //,date_list: []
                    };

                    if (itemTypeId === 6) {
                        itemHash[key].resource_id = objOcc.itemId;
                        itemHash[key].stock_level = objOcc.stock;
                        itemHash[key].reservation_id = objOcc.reservation_id;
                    } else {
                        itemHash[key].space_id = objOcc.itemId;
                    }
                } else {
                    let dt: any = { start_date: S25Util.date.toS25ISODateStr(objOcc.startDt) };
                    if (itemTypeId === 6) {
                        dt.stock = objOcc.stock;
                    }
                    //because reservation id is now part of key, we don't expect to use date list anymore
                    //keeping this check as a backup
                    if (!itemHash[key].date_list) {
                        itemHash[key].date_list = [];
                    }
                    itemHash[key].date_list.push(dt);
                }
            });

            let pendRsrvs: any = [];
            jSith.forEach(itemHash, function (_: any, item: any) {
                pendRsrvs.push(item);
            });

            let objType: string;
            let postBody: any;
            if (itemTypeId === 4) {
                objType = "sp";
                postBody = {
                    sp_pend_rsrvs: {
                        sp_pend_rsrv: pendRsrvs,
                    },
                };
            } else if (itemTypeId == 6) {
                objType = "rs";
                postBody = {
                    rs_pend_rsrvs: {
                        rs_pend_rsrv: pendRsrvs,
                    },
                };
            }

            return DataAccess.post(
                DataAccess.injectCaller("/" + objType + "_pend_rsrvs.json", "PendingObjectService.create"),
                postBody,
            ).then(
                function (data) {
                    let invalidItems = S25Util.propertyGetVal(data, "invalid_items");
                    let takenSpace = S25Util.propertyGetVal(data, "taken_pend_rsrv_space");
                    let takenDate = S25Util.propertyGetVal(data, "taken_space_pend_rsrv_dt");

                    let resourceRsrvs = S25Util.propertyGetVal(data, "resource_pend_rsrvs") || [];
                    let unavailResourceRsrv: any = [];
                    jSith.forEach(resourceRsrvs, function (_, rsrv) {
                        if (
                            rsrv &&
                            rsrv.qty_req &&
                            parseInt(rsrv.qty_req) &&
                            !isNaN(parseInt(rsrv.qty_req)) &&
                            parseInt(rsrv.qty_req) > parseInt(rsrv.qty_assign)
                        ) {
                            unavailResourceRsrv.push(rsrv);
                        }
                    });

                    //if any issues exist, return false, else return true
                    return !(invalidItems || takenSpace || takenDate || unavailResourceRsrv.length);
                },
                (err) => {
                    //ANG-5735 We couldn't create a pending reservation still return false
                    return false;
                },
            );
        }
    }

    //start_dt=20180610
    public static get(itemTypeId: number, startDt?: any, endDt?: any) {
        let objType = itemTypeId === 4 ? "sp" : "rs";
        let objName = itemTypeId === 4 ? "space" : "resource";
        let dt = startDt && endDt ? "?start_dt=" + startDt + "&end_dt=" + endDt : "";
        return DataAccess.get(
            DataAccess.injectCaller("/" + objType + "_pend_rsrvs.json" + dt, "PendingObjectService.get"),
        ).then((data: any) => {
            return (
                (data &&
                    data[objName + "_pend_rsrvs"] &&
                    data[objName + "_pend_rsrvs"][objName + "_pend_rsrv"] &&
                    S25Util.array.uniqueDeep(
                        S25Util.array.forceArray(data[objName + "_pend_rsrvs"][objName + "_pend_rsrv"]),
                    )) ||
                []
            );
        });
    }
}
